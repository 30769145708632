import { styled } from '@mui/system'
import Button from '../../molecules/button'
import Tooltip from '../../molecules/tooltip'

const shouldForwardProp = (prop) => !['left', 'top', 'allPinCssProp', 'singlePinCssProp'].includes(prop)

const Root = styled('div')`
	width: 100%;
	position: relative;
`

const PinsWrapper = styled('div')`
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 9;
`

const Pin = styled('div', { shouldForwardProp })`
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: transparent;
	display: block;
	position: absolute;
	top: ${({ top }) => top}%;
	left: ${({ left }) => left}%;
	cursor: pointer;
	transition: all 0.3s ease-in;
	filter: drop-shadow(0 0 10px #00000065);
	:after {
		content: '';
		display: block;
		width: 24px;
		height: 24px;
		background-color: ${({ theme }) => theme.palette.white.main};
		margin: 3px;
		border-radius: 50%;
		transition: all 0.3s ease-in;
	}
	${({ allPinCssProp }) => allPinCssProp};
	${({ singlePinCssProp }) => singlePinCssProp};
`

const ButtonClose = styled(Button)`
	color: ${({ theme }) => theme.palette.primary.main};
	margin: var(--spacing-2) var(--spacing-2) 0 auto;
	padding: calc(var(--spacing-1) * 0.5);
	position: absolute;
	z-index: 10;
	right: 0;
	.MuiSvgIcon-root {
		font-size: ${({ theme }) => theme.typography.pxToRem(24)};
	}
`

const TooltipStyled = styled(Tooltip)``

export { Root, PinsWrapper, Pin, ButtonClose, TooltipStyled }
